import { Atom, Cmp, state } from ":mods";
import { AModuleSubModule, EditModuleViewProps } from "../../models";
import { getModuleDetails } from "../../apis";
import { For, Match, Show, Switch, createEffect } from "solid-js";
import { SubmoduleForm } from "./submodule-form";
import { ModuleInfo } from "./side-info";
import { ROUTES } from "../../const";
import { getModuleRoute, getStageRoute } from "../../methods";
import { EditModuleDetails } from "./module-details";
import { SideNavItem } from "./sidenav-item";
import { EditSubModule } from "../edit-submodule";

export function EditModule({ $, ...props }: EditModuleViewProps) {
  const params = $.hooks.useParams();
  const moduleID = params.id;
  const $module_details = state.createAsync([getModuleDetails, moduleID]);
  const Layer = $.actions.createLayer({
    backMsg: "Back To Stages",
    events: {
      backTo: () => {
        if (!$module_details.state.loading) {
          const details = $module_details.unwrap.value;
          const stage_id = details.data.stage;
          return getStageRoute(stage_id); // your route
        }
        return undefined;
      },
    },
  });

  return (
    <Switch fallback={<div>unknown promise state </div>}>
      <Match when={$module_details.state.loading}>Loading...</Match>
      <Match when={$module_details.state.failed}>
        An error occured while fetching stage data {$module_details.error} ...
      </Match>
      <Match when={$module_details.state.success}>
        <Layer
          breadcrumb={[
            {
              title: "edit stage",
              to: getStageRoute($module_details.value.data.stage),
            },
          ]}
          items={[
            {
              title: "Module Settings",
              element: undefined,
              // searchParams: {
              //   module: data.id,
              // },
            },
            ...$module_details.value.data.submodule?.map((s) => ({
              title: s.name,
              element: () => <EditSubModule submoduleID={s.id} $={$} module={$module_details.value.data} />,
            })),
          ]}
        >
          <EditModuleDetails
            $={$}
            module={$module_details.value.data}
            onUpdate={(new_data) => {
              console.log("new_data :: ", new_data);
              $module_details.set((s) => {
                // s.data = { ...s.data, submodule: new_data };
                return { ...s, data: { ...s.data, submodule: new_data } };
              });
            }}
          />
        </Layer>
      </Match>
    </Switch>
  );
}
